<template>
  <v-container fluid>
    <v-card class="pa-5 mt-3">
      <v-tabs v-model="tab"
              fixed-tabs
              background-color="indigo"
              dark
      >
        <v-tab :key="1">
          {{ $t("videoLibrary.tab_courses") }}
        </v-tab>
        <v-tab :key="2">
          {{ $t("videoLibrary.tab_categories") }}
        </v-tab>
        <v-tab :key="3">
          {{ $t("videoLibrary.tab_videos") }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item
          :key="1"
        >
          <VideoLibraryCourses/>
        </v-tab-item>
        <v-tab-item
          :key="2"
        >
          <VideoLibraryCategories
            @categorySelected="categorySelected"
          />
        </v-tab-item>
        <v-tab-item
          :key="3"
        >
          <VideoLibraryVideos
            :category="selectedCategory"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>
<script>


import VideoLibraryCourses from "./Courses/Index.vue";
import VideoLibraryCategories from "./Categories/Index.vue";
import VideoLibraryVideos from "./Videos/Index.vue";

export default {
  components: {VideoLibraryVideos, VideoLibraryCategories, VideoLibraryCourses},

  data() {
    return {
      tab: 0,
      selectedCategory: null
    };
  },
  computed: {
    headers() {
      var list = [
        {text: this.$t("servers.domain"), value: "domain"},
        {text: this.$t("servers.ip"), value: "ip"},
        {text: this.$t("servers.max_schools"), value: "max_schools"},
        {
          text: this.$t("servers.allow_schools"), value: "allow_schools",
          map: (value) => value ? 'مسموح' : 'غير مسموح'
        },
      ];
      var actions = {
        text: "",
        value: "actions",
        templates: [],
      };
      actions.templates.push({
        type: "btn",
        icon: "mdi-pencil",
        text: this.$t("edit"),
        click: this.editItem,
      });
      actions.templates.push({
        type: "btn",
        icon: "mdi-delete",
        text: this.$t("delete"),
        click: this.deleteItem,
        color: "red darken-2",
      });
      list.push(actions);
      return list;
    },
  },
  created() {
    if (!this.$store.getters.isInRole(109)) {
      this.$router.push({name: "notauthorize"});
    }

  },
  methods: {
    categorySelected(value) {
      this.selectedCategory = value
      this.tab = 2
    }
  },
};
</script>
