<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-form ref="form" v-model="valid">
      <v-card>
        <v-toolbar color="primary darken-1" dark>
          <v-card-title>
            <span class="">{{
                (editedIndex === -1 ? $t("add") : $t("edit")) +
                " " +
                $t("videoLibrary.categories.index")
              }}</span>
          </v-card-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  outlined
                  hide-details
                  persistent-hint
                  v-model="editedItem.name"
                  :rules="[rulesWithLength.required]"
                  :label="$t('videoLibrary.categories.name')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-select
                  outlined
                  hide-details
                  persistent-hint
                  :items="courses"
                  item-text="title"
                  item-value="id"
                  v-model="editedItem.course_id"
                  :rules="[rules.required]"
                  :label="$t('videoLibrary.categories.course')"
                ></v-select>
              </v-col>
              <v-col cols="12" md="12">
                <v-select
                  outlined
                  hide-details
                  persistent-hint
                  :items="stages"
                  item-text="title"
                  item-value="id"
                  v-model="editedItem.stage"
                  :rules="[rules.required]"
                  :label="$t('videoLibrary.categories.stage')"
                ></v-select>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                  outlined
                  hide-details
                  type="number"
                  persistent-hint
                  v-model="editedItem.order"
                  :rules="[rules.required]"
                  :label="$t('videoLibrary.order')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="1">
                <v-switch
                  hide-details
                  persistent-hint
                  v-model="editedItem.active"
                  :label="$t('active')"
                ></v-switch>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            class="white--text"
            @click="save"
            :disabled="!valid || submitLoading"
            :loading="submitLoading"
            :min-width="100"
          >
            <v-icon>mdi-content-save-outline</v-icon>
            {{ $t("save") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="onClose">
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
export default {
  props: [
    "onSave",
    "onClose",
    "dialog",
    "editedItem",
    "editedIndex",
    "resetValidation",
    "courses",
    "stages",
  ],
  data() {
    return {
      valid: true,
      submitLoading: false,
      rules: {
        required: (value) => !!value || this.$t("ThisFieldIsRequired"),
      },
      rulesWithLength: {
        required: (value) =>
            (value != null && value.length < 255) ||
            this.$t("ThisFieldIsRequired"),
      },
    };
  },
  created() {

  },
  watch: {
    resetValidation() {
      this.resetForm();
    },
  },
  computed: {},
  methods: {
    resetForm() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    save() {
      var val = this.$refs.form.validate();
      if (val) {
        this.submitLoading = true;
        if (this.editedIndex > -1) {
          if (
              this.editedItem.active == undefined ||
              this.editedItem.active == null
          ) {
            this.editedItem.active = 0;
          } else if (
              this.editedItem.active == true ||
              this.editedItem.active == 1
          ) {
            this.editedItem.active = 1;
          } else {
            this.editedItem.active = 0;
          }
          this.$axios
              .post(
                  "video_library/categories/" + this.editedItem.id ,
                  this.editedItem
              )
              .then((response) => {
                if (response.data.status == "Successful") {
                  this.onClose();
                  this.$emit("refreshTable");
                }
              })
              .finally(() => {
                this.$emit("refreshTable");
                this.submitLoading = false;
              });
        } else {
          if (
              this.editedItem.active == undefined ||
              this.editedItem.active == null
          ) {
            this.editedItem.active = 0;
          } else if (
              this.editedItem.active == true ||
              this.editedItem.active == 1
          ) {
            this.editedItem.active = 1;
          } else {
            this.editedItem.active = 0;
          }

          this.$axios
              .post("video_library/categories", this.editedItem, {failureToast: true})
              .then((response) => {
                if (response.data.status == "Successful") {
                  this.onClose();
                  this.$emit("refreshTable");
                }
              })
              .finally(() => {
                this.$emit("refreshTable");
                this.submitLoading = false;
              });
        }
      }
    },
  },
};
</script>
