<template>
  <v-container fluid>
    <v-card class="pa-5 mt-3" :loading="loading">
      <v-toolbar flat>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          outlined
          dense
          :label="$t('search')"
          single-line
          hide-details
        ></v-text-field>
        <v-select
          outlined
          hide-details
          persistent-hint
          dense
          class="ms-1"
          :items="courses"
          item-text="title"
          item-value="id"
          clearable
          v-model="course_id"
          :label="$t('videoLibrary.categories.course')"
        ></v-select>
        <v-select
          outlined
          hide-details
          dense
          persistent-hint
          class="ms-1"
          :items="stages"
          item-text="title"
          item-value="id"
          clearable
          v-model="stage"
          :label="$t('videoLibrary.categories.stage')"
        ></v-select>
        <v-spacer></v-spacer>
        <v-btn
          v-if="$store.getters.isInRole(110)"
          color="primary"
          dark
          class="mb-2"
          @click="openAddDialog"
        >
          {{ $t("add") }}
          <v-icon> mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <data-table-component
        :headers="headers"
        :items="items"
        :search="search"
        :loading="loading"
      />
    </v-card>

    <Dialog
      :onClose="closeDialog"
      :dialog="dialog"
      :editedItem="editedItem"
      :editedIndex="editedIndex"
      :courses="courses"
      :stages="stages"
      :valid="valid"
      :resetValidation="resetValidation"
      @refreshTable="refreshTable"
    />
    <confirm-dialog
      :openDialog="dialogDelete"
      :onClicked="deleteItemConfirm"
      :onClose="closeDelete"
    />
  </v-container>
</template>
<script>
import ConfirmDialog from "../../../components/ConfirmDialog.vue";
import Dialog from "./Dialog.vue";
import DataTableComponent from "../../../components/DataTableComponent.vue";

export default {
  components: {DataTableComponent, ConfirmDialog, Dialog},
  name: "VideoLibraryCategories",

  data() {
    return {
      loading: true,
      valid: true,
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      courses: [],
      stages: [],
      course_id: null,
      stage: null,
      search: "",
      items: [],
      editedItem: {
        id: 0,
        name: null,
        course_id: null,
        stage: null,
        active: false,
      },
      specializations: [
        {
          text: this.$t("specializations.educational"),
          value: "educational",
        },
        {
          text: this.$t("specializations.specialty"),
          value: "specialty",
        },
      ],
      defaultItem: {
        id: 0,
        name: null,
        study_subjects: null,
        directorate_id: null,
        active: false,
        occupational_specialization: null,
      },
      resetValidation: 0,
    };
  },
  computed: {
    headers() {
      var list = [
        {text: this.$t("videoLibrary.order"), value: "order"},
        {text: this.$t("videoLibrary.categories.name"), value: "name"},
        {text: this.$t("videoLibrary.categories.course"), value: "course", map: (c) => c.title},
        {
          text: this.$t("videoLibrary.categories.stage"),
          value: "stageTitle",
        },
        {text: this.$t("videoLibrary.categories.videosCount"), value: "videosCount"},
        {
          text: this.$t("supervisors.active"),
          value: "active",
          type: "checkbox",
          readonly: true,
        },
      ];
      var actions = {
        text: "",
        value: "actions",
        templates: [
          {
            type: "btn",
            icon: "mdi-filmstrip",
            text: this.$t("videoLibrary.videos.index"),
            click: this.openCategoryVideos,
            color: "primary",
          }
        ],
      };
      if (this.$store.getters.isInRole(111)) {
        actions.templates.push({
          type: "btn",
          icon: "mdi-pencil",
          text: this.$t("edit"),
          click: this.editItem,
        });
      }
      if (this.$store.getters.isInRole(112)) {
        actions.templates.push({
          type: "btn",
          icon: "mdi-delete",
          text: this.$t("delete"),
          click: this.deleteItem,
          color: "red darken-2",
        });
      }
      list.push(actions);
      return list;
    },
  },
  created() {
    if (!this.$store.getters.isInRole(109)) {
      this.$router.push({name: "notauthorize"});
    }
    this.loading = true;
    this.$axios
        .get("video_library/courses")
        .then((response) => {
          this.courses = response.data.data;
        })
        .finally(() => {
          this.loading = false;
        });
    this.$axios
        .get("classes")
        .then((response) => {
          let classes = response.data.data
          this.stages = classes.filter(i => i.id < 5).flatMap((i) => {
            return [
              {header: i.name,},
              ...(i.stages.map(j => {
                return {
                  ...j,
                  title: j.name + " " + j.postfix
                }
              })),
              {divider: true}
            ]
          })
        })
        .finally(() => {
          this.loading = false;
        });
    this.refreshTable();
  },
  watch: {
    dialog(val) {
      val || this.closeDialog();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    course_id() {
      this.refreshTable();
    },
    stage() {
      this.refreshTable();
    }
  },
  methods: {
    openAddDialog() {
      this.editedItem.course_id = this.course_id
      this.editedItem.stage = this.stage
      this.dialog = true
    },
    openCategoryVideos(item) {
      this.$emit("categorySelected", item)
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      var deleteItem = this.items[this.editedIndex];
      this.$axios
          .delete("video_library/categories/" + deleteItem.id)
          .then(() => {
            this.refreshTable();
          });

      this.closeDelete();
    },
    closeDialog() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.resetValidation += 1;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    refreshTable() {
      this.loading = true
      this.$axios
          .get("video_library/categories?" + (this.course_id ? "course_id=" + String(this.course_id) : "") + (this.stage ? "&stage=" + String(this.stage) : ""), {failureToast: true})
          .then((response) => {
            this.items = response.data.data;
          })
          .finally(() => {
            this.loading = false;
          });
    },
  },
};
</script>
