<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-form ref="form" v-model="valid">
      <v-card>
        <v-toolbar color="primary darken-1" dark>
          <v-card-title>
            <span class="">{{
                (editedIndex === -1 ? $t("add") : $t("edit")) +
                " " +
                $t("videoLibrary.videos.index")
              }}</span>
          </v-card-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  hide-details
                  persistent-hint
                  v-model="editedItem.title"
                  :rules="[rulesWithLength.required]"
                  :label="$t('videoLibrary.videos.name')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  hide-details
                  persistent-hint
                  type="number"
                  v-model="editedItem.order"
                  :rules="[rules.required]"
                  :label="$t('videoLibrary.videos.order')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                  outlined
                  hide-details
                  persistent-hint
                  v-model="link"
                  :rules="[rulesWithLength.required]"
                  :label="$t('videoLibrary.videos.link')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  outlined
                  hide-details
                  persistent-hint
                  type="number"
                  v-model="seconds"
                  :rules="[timeRules.required]"
                  :label="$t('videoLibrary.videos.seconds')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  outlined
                  hide-details
                  persistent-hint
                  type="number"
                  v-model="minutes"
                  :rules="[timeRules.required]"
                  :label="$t('videoLibrary.videos.minutes')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  outlined
                  hide-details
                  persistent-hint
                  type="number"
                  v-model="hours"
                  :rules="[timeRules.required]"
                  :label="$t('videoLibrary.videos.hours')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-textarea
                  outlined
                  hide-details
                  persistent-hint
                  v-model="editedItem.details"
                  :label="$t('videoLibrary.videos.details')"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="12" class="mb-0 pb-0">
                <v-file-input
                  outlined
                  hide-details
                  ref="refInputEl"
                  v-model="image"
                  type="file"
                  @click:clear="image = null"
                  accept=".jpeg,.png,.jpg,.gif"
                  :label="$t('advertisements.image')"
                ></v-file-input>
                <v-img :src="imagePreview" max-height="300" class="px-3 my-3 rounded"
                       style="border: 1px solid grey;height: 300px"/>
                <v-btn v-if="link" @click="imageFromUrl" style="margin-top: -6rem;margin-right: 8px" color="secondary">
                  جلب الصورة من الرابط
                </v-btn>
                <v-chip color="info" style="margin-top: -6rem;margin-right: 8px" v-if="imagePreview">
                  {{
                    imageUrl && !image ?
                      'الصورة من الرابط'
                      :
                      'الصور من ملف'
                  }}
                </v-chip>
              </v-col>
              <v-col cols="12" md="1">
                <v-switch
                  hide-details
                  persistent-hint
                  v-model="editedItem.active"
                  :label="$t('supervisors.active')"
                ></v-switch>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            class="white--text"
            @click="save"
            :disabled="!valid || submitLoading"
            :loading="submitLoading"
            :min-width="100"
          >
            <v-icon>mdi-content-save-outline</v-icon>
            {{ $t("save") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="onClose">
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>

export default {
  props: [
    "onSave",
    "onClose",
    "dialog",
    "editedItem",
    "editedIndex",
    "resetValidation",
    "category"
  ],
  data() {
    return {
      valid: true,
      submitLoading: false,
      image: null,
      imagePreview: null,
      imageUrl: null,
      link: null,
      hours: '0',
      minutes: '0',
      seconds: '0',
      rules: {
        required: (value) => !!value || this.$t("ThisFieldIsRequired"),
      },
      timeRules:{
        required: (value) => (value != null && parseInt(value) < 60 && parseInt(value) >= 0) ||
            'مدخل خاطئ',
      },
      rulesWithLength: {
        required: (value) =>
            (value != null && value.length < 255) ||
            this.$t("ThisFieldIsRequired"),
      },
    };
  },
  created() {

  },
  mounted() {

  },
  watch: {
    resetValidation() {
      this.resetForm();
    },

    image: {
      handler() {
        this.editedItem.image = this.image
        this.fileChanged()
      }
    },
    link: {
      handler() {
        if (!this.imagePreview) {
          this.imageFromUrl()
        }
      }
    },
    editedItem: {
      handler() {
        this.imageUrl = this.editedItem.image_url
        this.image = this.editedItem.image
        this.imagePreview = this.editedItem.image ?? this.editedItem.image_url
        this.link = this.editedItem.link
        this.seconds = Math.floor(this.editedItem.duration % 3600 % 60)
        this.minutes = Math.floor(this.editedItem.duration % 3600 / 60)
        this.hours = Math.floor(this.editedItem.duration / 3600)
      }
    }
  },
  computed: {},
  methods: {
    resetForm() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    save() {
      var val = this.$refs.form.validate();
      if (val) {
        this.submitLoading = true;

        const payload = new FormData();
        payload.append('title', this.editedItem.title)
        payload.append('link', this.link)
        if (this.image)
          payload.append('image', this.image)
        else
          payload.append('image_url', this.imageUrl)

        payload.append('details', this.editedItem.details ? String(this.editedItem.details) : '')
        payload.append('order', this.editedItem.order)
        payload.append('duration', (this.hours * 3600) + (this.minutes * 60) + (this.seconds * 1))
        payload.append('active', this.editedItem.active === true || this.editedItem.active === 1 ? '1' : '0')
        if (this.editedIndex > -1) {

          this.$axios
              .post(
                  "video_library/videos/" + this.editedItem.id + "?category_id=" + this.category.id,
                  payload
              )
              .then((response) => {
                if (response.data.status === "Successful") {
                  this.onClose();
                  this.$emit("refreshTable");
                }
              })
              .finally(() => {
                this.$emit("refreshTable");
                this.submitLoading = false;
              });
        } else {

          this.$axios
              .post("video_library/videos?category_id=" + this.category.id, payload, {failureToats: true})
              .then((response) => {
                if (response.data.status === "Successful") {
                  this.onClose();
                  this.$emit("refreshTable");
                }
              })
              .finally(() => {
                this.$emit("refreshTable");
                this.submitLoading = false;
              });
        }
      }
    },
    fileChanged() {
      if (!this.image) {
        this.imagePreview = null
        return null
      }

      this.imageUrl = null
      this.imageUrl = null
      const reader = new FileReader()
      reader.readAsDataURL(this.image)
      reader.onload = () => {
        this.imagePreview = reader.result
      }
      reader.onerror = error => {
        console.log('Error: ', error)
      }

      return null
    },
    imageFromUrl() {
      this.image = null
      if (!this.link) {
        this.imagePreview = null
        return
      }
      const regExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\\&\\?]*).*/;
      const match = this.link.match(regExp);
      if (match && match[1].length === 11) {
        let imageUrl = 'https://img.youtube.com/vi/' + match[1] + '/mqdefault.jpg';
        this.imageUrl = imageUrl
        this.imagePreview = imageUrl
      }
    }
  },
};
</script>
